import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Badge
} from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import { useOrganizationStore } from "../../../Store/store";
import { Utility } from "../../../utils";
import NoDataLabel from "./NoDataLabel";
import {
  CampaignStatus,
  CampaignType
} from "../../../Components/constants/campaign";
import { iconMap } from "./IconMap";
import {
  CampaignName,
  TCampaignModelJSON,
  TCampaignUpdateRequest
} from "@shoutout-labs/market_buzz_crm_types";
import UiCampaignEditModal from "../../../pages/BaseUi/UiModals/UiCampaignEditModal";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { updateCampaign } from "../../../services";

const AutomatedCampaignCard: React.FC<{
  campaigns: TCampaignModelJSON[];
  isLoading: boolean;
}> = ({ campaigns, isLoading }) => {
  const [organization] = useOrganizationStore((state) => [state.organization]);
  const [selectedCampaign, setSelectedCampaign] =
    useState<TCampaignModelJSON>(null);

  const initialIsEnableMap = useMemo(() => {
    if (campaigns && campaigns.length > 0) {
      return campaigns.reduce(
        (acc, campaign) => {
          acc[campaign.id] = campaign.status === CampaignStatus.ACTIVE;
          return acc;
        },
        {} as Record<string, boolean>
      );
    }
    return {};
  }, [campaigns]);

  const [isEnableMap, setIsEnableMap] = useState<Record<string, boolean>>({});

  const mutationUpdateCampaign = useMutation({
    mutationFn: (params: {
      payload: TCampaignUpdateRequest;
      campaignId: string;
    }) => {
      return updateCampaign(params.payload, params.campaignId);
    },
    onSuccess: () => {
      toast.success("Campaign is updated");
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const toggleSwitch = (campaignId: string) => {
    setIsEnableMap((prev) => ({
      ...prev,
      [campaignId]: !prev[campaignId]
    }));
    const updatePayload = {
      status: isEnableMap[campaignId]
        ? CampaignStatus.INACTIVE
        : CampaignStatus.ACTIVE
    };
    mutationUpdateCampaign.mutate({ payload: updatePayload, campaignId });
  };

  const selectCampaign = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      const id = e.currentTarget.dataset.id;
      if (id) {
        setSelectedCampaign(campaigns.find((item) => item.id === id));
      }
    },
    [setSelectedCampaign, campaigns]
  );

  const onCloseModal = useCallback(() => {
    setSelectedCampaign(null);
  }, [setSelectedCampaign]);

  useEffect(() => {
    setIsEnableMap(initialIsEnableMap);
  }, [initialIsEnableMap]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Row>
          {campaigns.length > 0 &&
            campaigns.map((campaign, index) => (
              <Col xl={14} key={index}>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col xl={4} lg={4} md={4} sm={12}>
                          <h6 className="mt-0">
                            {iconMap[campaign.name]} {campaign.name}
                            <Badge color="primary" className="mx-2">
                              {campaign.channel}
                            </Badge>
                          </h6>
                          <Row>
                            <p>
                              {Utility.replaceOrganizationCustomAttribute(
                                campaign.content,
                                organization?.name
                              )}
                            </p>
                          </Row>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={6}>
                          <p className="d-flex justify-content-center">
                            Triggered
                          </p>
                          <Row>
                            <p className="d-flex justify-content-center">
                              {campaign.report?.processedMessageCount || 0}
                            </p>
                          </Row>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={6}>
                          <p className="d-flex justify-content-center">Sent</p>
                          <Row>
                            <p className="d-flex justify-content-center">
                              {campaign.report?.sentMessageCount || 0}
                            </p>
                          </Row>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={3}>
                          <p></p>
                          <Row>
                            <p>
                              <FormGroup switch>
                                <Input
                                  type="switch"
                                  checked={isEnableMap[campaign.id] || false}
                                  onClick={() => toggleSwitch(campaign.id)}
                                />
                                <Label check>
                                  {isEnableMap[campaign.id]
                                    ? "Active"
                                    : "Inactive"}
                                </Label>
                              </FormGroup>
                            </p>
                          </Row>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={3}>
                          <p></p>
                          <Row>
                            <p>
                              <Button
                                color="outline-primary"
                                onClick={selectCampaign}
                                data-id={campaign.id}
                              >
                                Edit Content
                              </Button>
                            </p>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          {campaigns.length === 0 && (
            <NoDataLabel
              title="Let's Get Your Automated Campaigns Started!"
              content="You haven't activated any campaigns yet. Pick a template and enable it to start sending automated messages to your customers."
            />
          )}
        </Row>
      )}
      {!!selectedCampaign && (
        <UiCampaignEditModal
          showModal={!!selectedCampaign}
          closeModal={onCloseModal}
          fade={true}
          messageText={selectedCampaign?.content}
          name={selectedCampaign?.name as unknown as CampaignName}
          type={selectedCampaign?.type as unknown as CampaignType}
          isEditCampaign={true}
          campaignId={selectedCampaign?.id}
        />
      )}
    </>
  );
};

export default AutomatedCampaignCard;
