import CustomerPage from "../Components/Modules/Customers/CustomerPage";
import ProfilePage from "../Components/Modules/Profile/ProfilePage";
import CampaignPage from "../Components/Modules/Campaigns/CampaignPage";
import BillingPage from "../Components/Modules/Billing/BillingPage";
import Login from "../pages/Authentication/Login";
import DashboardPage from "../Components/Modules/Dashboard/DashboardPage";
import SalesReportPage from "../Components/Modules/Analytics/Sales/SalesReportPage";
import CustomersReportPage from "../Components/Modules/Analytics/Customers/CustomersReportPage";
import SwitchAccount from "../pages/SuperAdmin/Authentication/SwitchAccountPage";
import AutomatedCampaignPage from "../Components/Modules/Campaigns/AutomatedCampaignPage";
import { SalesTrendHeatmapPaage } from "../Components/Modules/Analytics/SalesTrend/SalesTrendHeatmapPaage";

const authProtectedRoutes = [
  { path: "/", component: <DashboardPage /> },
  { path: "/customers", component: <CustomerPage /> },
  { path: "/profile", component: <ProfilePage /> },
  { path: "/campaigns/sms", component: <CampaignPage /> },
  { path: "/campaigns/automated", component: <AutomatedCampaignPage /> },
  { path: "/billing", component: <BillingPage /> },
  { path: "/reports/sales", component: <SalesReportPage /> },
  { path: "/reports/customers", component: <CustomersReportPage /> },
  { path: "/admin/switch", component: <SwitchAccount /> },
  { path: "/reports/sales-trend", component: <SalesTrendHeatmapPaage /> }
];

const publicRoutes = [
  // Authentication Page
  // { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> }
];

export { authProtectedRoutes, publicRoutes };
