import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Common/BreadCrumb";
import { SalesTrend } from "./SalesTrend";

export const SalesTrendHeatmapPaage = () => {
  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Transaction Heatmap" pageTitle="Reports" />
          <SalesTrend />
        </Container>
      </div>
    </div>
  );
};
